<template>
  <div>
    <div class="float-right mr-2" style="width: 200px">
      <div class="mt-n3 position-absolute" style="width: 200px">
        <feather-icon
          v-if="isStaff && !editing"
          icon="Edit2Icon"
          size="20"
          class="text-primary edit-icon"
          role="button"
          @click="editing = !editing"
        />
        <feather-icon
          v-if="editing"
          icon="XCircleIcon"
          size="20"
          class="text-primary x-icon ml-5"
          role="button"
          @click="editing = !editing"
        />
        <b-button
          v-if="editing"
          class="float-right mt-n50"
          variant="primary"
          @click="editAbout"
        >
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
    </div>
    <b-card>
      <div v-if="editing">
        <b-form-radio-group
          v-model="selected"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          class="mr-1 order-lg-0 order-1 mb-2 d-none d-lg-flex"
        />
        <b-form-group>
          <quill-editor
            id="type"
            class="quillE"
            v-model="textAbout[selected || currentLocale]"
            :placeholder="$t('form.select-placeholder')"
            label="name"
          />
        </b-form-group>
      </div>
      <div
        v-else-if="
          collective.about &&
          collective.about.length !== 0 &&
          computedAbout != undefined
        "
      >
        <div class="about-description">
          <b-embed
            v-if="collective.url && !isImage"
            class="mt-1"
            type="iframe"
            aspect="16by9"
            :src="collective.url"
            allowfullscreen
          />
          <img
            v-if="collective.url && isImage"
            :src="collective.url"
            :alt="collective.name"
            width="100%"
          />
          <!-- Presentation -->
          <b-row class="d-flex align-items-start">
            <b-col cols="12" class="about-information">
              <content-with-anchors tag="p" :content="computedAbout" />
            </b-col>
            <b-col v-if="hasInformation" xs="6" lg="5" xl="4" class="pl-lg-5">
              <!-- Dates information -->
              <div
                v-if="collective.about.dates"
                class="d-flex align-items-center mb-2"
              >
                <b-avatar
                  variant="light-primary"
                  rounded="sm"
                  class="rounded about-icons"
                >
                  <feather-icon icon="CalendarIcon" size="16" />
                </b-avatar>
                <h6 class="mb-0 ml-1">
                  {{ collective.about.dates }}
                </h6>
              </div>
              <!-- Location information -->
              <div
                v-if="collective.about.location"
                class="d-flex align-items-center"
              >
                <b-avatar
                  variant="light-primary"
                  rounded="sm"
                  class="rounded about-icons"
                >
                  <feather-icon icon="MapPinIcon" size="16" />
                </b-avatar>
                <h6 class="mb-0 ml-1">
                  {{ collective.about.location }}
                </h6>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Container without data: Placeholder -->
      <div v-else class="mt-0 pt-0">
        <b-row class="horizontal-placeholder">
          <b-col v-if="aboutPlaceholder" cols="12">
            <img :src="aboutPlaceholder" />
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("members.no-about-message") }}
            </p>
          </b-col>
        </b-row>
      </div>

      <b-modal
        v-model="canEdit"
        :title="$t('events.actions.edit-about')"
        @ok="editAbout"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('action.cancel')"
        size="xl"
      >
        <b-form-radio-group
          v-model="selected"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          class="mr-1 order-lg-0 order-1 mb-2 d-none d-lg-flex"
        />
        <b-form-group>
          <quill-editor
            id="type"
            class="quillE"
            v-model="textAbout[selected || currentLocale]"
            :placeholder="$t('form.select-placeholder')"
            label="name"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BEmbed, BRow, BCol, BAvatar, BFormGroup } from "bootstrap-vue";
import AboutPlaceholder from "@/assets/images/placeholders/light/aboutCommunity.svg";
import WidgetLayoutMixin from "@core/mixins/widgets/WidgetLayoutMixin";
import AppScroll from "@core/components/app-scroll/AppScroll.vue";
import ContentWithAnchors from "@core/components/string-utils/ContentWithAnchors.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { quillEditor } from "vue-quill-editor";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { AppIDLUT } from "@copernicsw/community-common";

export default {
  components: {
    BEmbed,
    BRow,
    BCol,
    BAvatar,
    ListContainerWidget,
    AppScroll,
    ContentWithAnchors,
    BFormGroup,
    quillEditor,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  data() {
    return {
      canEdit: false,
      textAbout: { es: null, ca: null, en: null },
      editing: false,
      selected: "",
    };
  },
  computed: {
    appId() {
      return 65;
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 65)
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
    isAdmin() {
      return this.$store.getters.loggedMemberRoles.filter(({ tagName }) => tagName === 'admin').length > 0;
    },
    locales() {
      return this.$store.getters.locales;
    },
    isStaff() {
      return this.collective.isStaff ? this.collective.isStaff : this.isAdmin;
    },
    isImage() {
      return !!this.presentation.url.endsWith(".jpg");
    },
    placeholder() {
      return AboutPlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },

    computedAbout() {
      if (this.collective.about.body) {
        this.textAbout[this.currentLocale] = this.collective.about.body;
        return this.collective.about.body;
      } else {
        this.textAbout = this.collective.about;
        return this.textAbout[this.currentLocale] != null &&
          this.textAbout[this.currentLocale] !== ""
          ? this.textAbout[this.currentLocale]
          : Object.values(this.textAbout).find((obj) => {
              return obj != null && obj != "";
            });
      }
    },
    hasInformation() {
      return (
        this.collective.about &&
        (this.collective.about.dates || this.collective.about.location)
      );
    },
    aboutPlaceholder() {
      return AboutPlaceholder;
    },
  },
  created() {
    this.selected = this.currentLocale;
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  methods: {
    isEditing() {
      this.canEdit = true;
    },
    async editAbout() {
      for (const property in this.textAbout) {
        if (this.textAbout[property] === "") {
          this.textAbout[property] = null;
        }
      }
      const response = await this.$store.dispatch("putAddon", {
        about: this.textAbout,
      });
      if (this.collective.about == null) {
        this.collective.about = {};
      }
      this.collective.about = this.textAbout;
      this.editing = false;
      this.notifySuccess(this.$t("members.edit.success-message-community"));
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
          this.appTitle || this.$t("about.title"),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.quillE {
  width: 100%;
  // height: 900px!important;
  // margin-bottom: 50px;
}

.about-description {
  margin-right: 20px;
}
.about-placeholder {
  display: block;
  margin: 2rem auto;
  margin-bottom: 1rem;
  max-width: 200px;
}
.placeholder-text {
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 500;
  color: $primary;
}
.about-icons {
  width: 34px;
  height: 34px;
}
.edit-icon {
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.x-icon {
  position: absolute;
  cursor: pointer;
}
</style>
